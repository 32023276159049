<template>
  <div>
    <Header></Header>
    <div class="content">
      <div class="bread_nav flex-box">
        <div class="bread fs16 col3">
          当前位置：<router-link to="/">首页</router-link>><a href="javascript:;">商品详情</a>
        </div>
      </div>
      <div class="detail">
        <div class="m1440 flex-box flex-col-start pt40">
          <div>
            <div class="mySwiper2">
              <swiper :options="swiperOption" ref="swiperTop" v-if="mainPic.length > 0">
                <swiper-slide v-for="(item, index) in mainPic" :key="index">
                  <img :src="item" />
                </swiper-slide>
              </swiper>
            </div>
            <div class="thumb_box">
              <div class="swiper mySwiper">
                <swiper :options="swiperOptionThumbs" class="gallery-thumbs" ref="swiperThumbs"
                  v-if="mainPic.length > 0">
                  <swiper-slide v-for="(item, index) in mainPic" :key="index"><img :src="item" />
                  </swiper-slide>
                </swiper>
              </div>
              <div class="swiper-button-next"></div>
              <div class="swiper-button-prev"></div>
            </div>
          </div>
          <div class="flex-grow-1 pl30">
            <div class="fs26 fwb">{{ detail.productName }}</div>
            <div class="price_box flex-box pl20 mt20 mb30">
              <span class="col9 letter">价格</span><span class="col9">:</span>
              <div class="pl10 flex-grow-1">
                <span class="red fs16 fwb">¥</span><span class="red fs28 fwb">{{detail.price_text?detail.price_text:detail.price }}</span><span class="col9 fs16">{{ detail.unit?detail.unit:'' }}</span>
              </div>
              <div class="fs16 pr20">成交量 <span class="normal fs20 fwb">{{ detail.transactionNum }}</span></div>
            </div>

			<!-- add mao 240417-->
			<div class="flex-box pl20 mb30">
			<span class="col9 letter">产品类型</span><span class="col9">:</span>
			<span class="pl10 flex-grow-1">{{ detail.productTy }}</span>
			</div>
			<!-- add end -->
			<!-- add mao 240412--> 
			<div class="flex-box pl20 mb30">
			<span class="col9 letter">产品小类</span><span class="col9">:</span>
			<span class="pl10 flex-grow-1">{{ detail.productSub }}</span>
			</div>
			<!-- add end -->
            <div class="flex-box pl20 mb30">
              <span class="col9 letter">技术类型</span><span class="col9">:</span>
              <span class="pl10 flex-grow-1">{{ detail.tecType }}</span>
            </div>
            <div class="flex-box pl20 mb30">
              <span class="col9 letter">年份</span><span class="col9">:</span>
              <span class="pl10">{{ detail.year }}</span>
            </div>
            <div class="flex-box pl20 mb30">
              <span class="col9 letter">库存</span><span class="col9">:</span>
              <span class="pl10">{{ detail.stocks_text?detail.stocks_text:detail.stocks}}</span>
            </div>
            <div class="flex-box pl20">
              <span class="col9 letter">项目名称</span><span class="col9">:</span>
              <span class="pl10">{{ detail.projectName ? detail.projectName : '' }}</span>
            </div>
            <div class="num_buy flex-box">
              <div class="flex-box num_box">
                <input type="text" v-model="buyNum" @blur="inputNum" class="flex-grow-1 tc fs20" />
                <div>
                  <div class="plus" @click="plus()">+</div>
                  <div class="minus" @click="minus()">-</div>
                </div>
              </div>
              <div class="buy_now fs26" @click="nowBuy">立即购买</div>
            </div>
          </div>
        </div>
        <div class="m1440 flex-box introduce">
          <div class="fs20">商品介绍</div>
        </div>
        <div class="m1440 intro_detail">
          <div v-html="detail.description"></div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import cookie from "vue-cookies";
export default {
  components: {
    swiper,
    swiperSlide,
  },

  data() {
    return {
      swiperOption: {
        loop: true,
        loopedSlides: 5, //looped slides should be the same
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      swiperOptionThumbs: {
        touchRatio: 0.2,
        loop: true,
        loopedSlides: 5, //looped slides should be the same
        slideToClickedSlide: true,
        spaceBetween: 14,
        slidesPerView: 5,
        centeredSlides: true,
      },
      id: 0,
      oss: this.global.uploadOss,
      detail: {},
      buyNum: 1, //购买数量
      mainPic: [],
    };
  },

  watch: {
    $route() {
      let that = this;
      that.goods_detail(that.$route.query.id);
    },
    deep: true,
  },
  created() {
    // this.id = this.$route.query.id
    // this.goods_detail(this.id)
  },
  mounted() {
    // console.log('id为'+ this.$route.query.id)
    this.id = this.$route.query.id
    this.goods_detail(this.id)
  },
  methods: {
    inputNum(e) {
      let that = this
      let num = (e.target.value).toString().replace(/,/g, "")
      if (num <= 0) {
        that.$message.error('购买数量不能低于1');
        that.buyNum = 1
        return;
      }
      that.buyNum = that.global.addCommas(num)
    },

    plus() {
      let that = this
      let num = that.buyNum.toString().replace(/,/g, "")
      num++
      that.buyNum = that.global.addCommas(num)
    },
    minus() {
      let that = this
      let num = that.buyNum.toString().replace(/,/g, "")
      if (num <= 1) {
        that.$message.error('购买数量不能低于1');
        return;
      }
      num--
      that.buyNum = that.global.addCommas(num)
    },

    //立即购买
    nowBuy() {
      let that = this;
      let num = that.buyNum.toString()
      let num_text = num.replace(/,/g, "")
      if (num_text <= 0) {
        this.$message.error('购买数量不能低于1');
        return;
      }
      console.log(num_text)
      var r = /^\+?[1-9][0-9]*$/;　　//正整数 
      if(r.test(num_text)==false){
        this.$message.error('请输入正确的数量');
        return;
      }
      var s_data = that.detail
      if (s_data.stocks <= 0 || s_data.stocks < num_text) {
        that.$message.error('当前产品库存不足');
        return;
      }
      let userinfo = cookie.get("huanbaoqiao_userinfo");
      if(userinfo){
        var id = userinfo.id
      }else{
        var id = 0
        that.$message.error('请先登录');
        that.$router.push("/login")
        return;
      }
      that.$api.apiUserInfo({id: id}).then((res) => {
        console.log(res)
        if (res.resCode == 0) {
          // console.log(res.root)
          if(!res.root[0].contactorEmail){
            that.$message.error('请完善邮箱等基本信息');
            that.$router.push("/info")
            return;
          }
          if(res.root[0].emailChecked==0){
            that.$message.error('请先完成邮箱验证');
            that.$router.push("/info")
            return;
          }
  // mao modify 240327
          if (s_data.productTy=='ER'){
          // that.$router.push({ name: 'Fillin_info', params: { goods_id: that.id, goods_num: num } });
          that.$router.push({ name: 'Fillin_info_er', params: { goods_id: that.id, goods_num: num } });
          }else {
            that.$router.push({ name: 'Fillin_info', params: { goods_id: that.id, goods_num: num } });
          }
          // end modify
        }else if(res.resCode ==401){
          that.$message.error('请先登录');
          that.$router.push("/login")
          return;
        } else {
          that.$message.error(res.resMsg);
        }
      });
    },

    //商品详情
    goods_detail(id) {
      let that = this
      that.$api.apiGoodsDetail({ id: id, deviceType: '1' }).then((res) => {
        if (res.resCode == 0) {
          let s_data = res.root[0]
          if (s_data.mainPic != null && s_data.mainPic != '') {
            let img = s_data.mainPic.split(",")
            let newArr = [];
            img.forEach(v => {
              var k = that.oss + v
              newArr.push(k)
            });
            that.mainPic = newArr
            that.$nextTick(() => {
              const swiperTop = that.$refs.swiperTop.swiper;
              const swiperThumbs = that.$refs.swiperThumbs.swiper;
              swiperTop.controller.control = swiperThumbs;
              swiperThumbs.controller.control = swiperTop;
            });
          }else{
            that.mainPic = []
          }
          s_data.stocks_text = that.global.addCommas(s_data.stocks)
          s_data.price_text = that.global.addCommas(s_data.price)
          that.detail = s_data
        } else {
          that.$message.error(res.resMsg);
        }
      });
    },
  },

};
</script>
